import React from 'react';
import PropTypes from 'prop-types';
import { stack as Menu } from 'react-burger-menu';
import { useMedia } from 'use-media';
import { TiSocialFacebook as FbIcon } from 'react-icons/ti';
import { IoMdPhonePortrait as Phone } from 'react-icons/io';
import menu from '../data/menu';
import scroll from '../helpers/scroll';
import * as ga from '../utils/ga';
import Nav from './Nav';
import css from './MobileMenu.module.css';
import AppContext from './AppContext';

const MobileMenu = ({ setIsMenuOpen, isMenuOpen }) => {
   const isVisible = useMedia({ maxWidth: '768px' });
   const { setActiveMenuItem } = React.useContext(AppContext);

   function handleOnOverlayClick({ isOpen }) {
      if (isOpen !== isMenuOpen) {
         setIsMenuOpen(isOpen);
      }
   }

   return (
      isVisible && (
         <Menu
            isOpen={isMenuOpen}
            crossClassName={css.cross}
            itemListClassName={css.itemList}
            itemClassName={css.menuItem}
            right
            width={280}
            onStateChange={handleOnOverlayClick}
            customBurgerIcon={false}
         >
            {menu.map(({ label, href, blank }) => (
               <Nav.MenuItem
                  key={href}
                  label={label}
                  noStyles
                  href={href}
                  blank={blank}
                  onClick={() => {
                     setIsMenuOpen(false);
                  }}
               />
            ))}
            <Nav.MenuItem
               onClick={() => {
                  scroll('/#kontakt');
                  setIsMenuOpen(false);
                  setActiveMenuItem('/#kontakt');
               }}
               noStyles
               href="/#kontakt"
               label="Kontakt"
            />
            <a
               href="https://www.facebook.com/moskitieryroletyoknakrakow"
               target="_blank"
               rel="noopener noreferrer"
               className={css.fbIcon}
               onClick={() => {
                  ga.outboundClick('https://www.facebook.com/moskitieryroletyoknakrakow');
               }}
            >
               <FbIcon size="25" />
            </a>

            <a
               href="tel:504177380"
               className={css.phoneNumber}
               onClick={() => {
                  ga.outboundClick('Clicked on phone number');
               }}
            >
               <Phone className={css.cellPhoneIcon} size="22" />
               504 177 380
            </a>
         </Menu>
      )
   );
};

MobileMenu.propTypes = {
   setIsMenuOpen: PropTypes.func.isRequired,
   isMenuOpen: PropTypes.bool.isRequired,
};

export default MobileMenu;
