import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { navigate } from '@reach/router'; // eslint-disable-line
import scroll from '../helpers/scroll';
import LogoIcon from '../images/logo.svg';
import css from './Logo.module.css';

const cls = classNames.bind(css);

const Logo = ({ isShrank, className, isWhite, style, logoOnly, withTransition, containerClassName, ...props }) => {
   return (
      <LogoIcon
         style={style}
         {...props}
         onClick={() => {
            navigate('/');
            scroll('/#start');
         }}
         alt="logo moskitom"
         className={cls(css.logo, className, { shrankLogo: isShrank, withTransition, logoOnly, isWhite })}
      />
   );
};

Logo.propTypes = {
   isShrank: PropTypes.bool,
   withTransition: PropTypes.bool,
   isWhite: PropTypes.bool,
   className: PropTypes.string,
   containerClassName: PropTypes.string,
   logoOnly: PropTypes.bool,
   style: PropTypes.object,
};

Logo.defaultProps = {
   className: undefined,
   withTransition: false,
   isWhite: false,
   containerClassName: undefined,
   style: undefined,
   isShrank: undefined,
   logoOnly: false,
};

export default Logo;
