import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './BurgerIcon.module.css';
const cls = classnames.bind(styles);

// https://github.com/mattvox/react-css-burger
const Burger = ({ active, onClick, burger, className }) => {
   const buttonClasses = cls('hamburger', `hamburger${burger}`, className, { isActive: active });

   return (
      <button onClick={onClick} className={buttonClasses} type="button">
         <span className={styles.hamburgerBox}>
            <span className={styles.hamburgerInner} />
         </span>
      </button>
   );
};

Burger.propTypes = {
   active: PropTypes.bool.isRequired,
   onClick: PropTypes.func.isRequired,
   className: PropTypes.string,
   burger: PropTypes.oneOf([
      'Arrow',
      'Arrowalt',
      'Arrowturn',
      'Boring',
      'Collapse',
      'Elastic',
      'Emphatic',
      'Minus',
      'Slider',
      'Spin',
      'Spring',
      'Squeeze',
      'Stand',
      'Vortex',
      '3dx',
      '3dy',
      '3dxy',
   ]),
};

Burger.defaultProps = {
   burger: 'collapse',
   className: undefined,
};

export default Burger;
