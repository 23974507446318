import React from 'react';
import Logo from './Logo';
import Nav from './Nav';
import css from './Footer.module.css';

const Footer = () => {
   return (
      <section className={css.footer}>
         <hr className={css.divider} />
         <div className={css.quickLinks}>
            <Logo className={css.logo} isWhite />
            <Nav noStyles className={css.nav}>
               <Nav.MenuItem noStyles label="Polityka prywatności" href="/polityka-prywatnosci" />
            </Nav>
         </div>
         <footer>© {new Date().getFullYear()}, Moskitom</footer>
      </section>
   );
};

export default Footer;
