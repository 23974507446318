import { useEffect, useState } from 'react';

const SCROLL_THRESHOLD = 500;
const SCROLL_THRESHOLD_BOTTOM = 250;
const BOTTOM = 'bottom';
const TOP = 'top';

export default function useNavbar() {
   const [isShrank, setIsShrank] = useState(false);
   const [isVisible, setIsVisible] = useState(true);
   const [prevScroll, setPrevScroll] = useState(0);
   const [directionChangedAt, setDirectionChangedAt] = useState(0);
   const [curDirection, setCurDirection] = useState(BOTTOM);

   useEffect(() => {
      function handleScrollEvent() {
         const { pageYOffset: curScroll } = window;

         setIsShrank(curScroll >= 10);
         const direction = curScroll > prevScroll ? BOTTOM : TOP;

         if (direction === BOTTOM) {
            setIsVisible(curScroll - directionChangedAt < SCROLL_THRESHOLD);
         } else if (!isVisible && directionChangedAt - curScroll > SCROLL_THRESHOLD_BOTTOM) {
            setIsVisible(directionChangedAt - curScroll > SCROLL_THRESHOLD_BOTTOM);
         }

         if (curDirection !== direction) {
            setDirectionChangedAt(curScroll);
            setCurDirection(direction);
         }

         if (Math.abs(curScroll - prevScroll) > 100) {
            setPrevScroll(curScroll);
         }
      }

      window.addEventListener('scroll', handleScrollEvent);

      return () => {
         window.removeEventListener('scroll', handleScrollEvent);
      };
   }, [curDirection, directionChangedAt, isVisible, prevScroll]);

   return [isShrank, isVisible];
}
