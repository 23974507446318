import React from 'react';
import styles from './Spinner.module.css';

const Spinner = () => {
   return (
      <div className={styles.ballsContainer}>
         <div className={styles.balls}>
            <div />
            <div />
            <div />
         </div>
      </div>
   );
};

export default Spinner;
