import React from 'react';
import PropTypes from 'prop-types';
import useMedia from 'use-media';
import classNames from 'classnames/bind';
import useNavbar from '../hooks/useNavbar';
import BurgerIcon from './BurgerIcon';
import Logo from './Logo';
import Nav from './Nav';
import styles from './Header.module.css';

const cls = classNames.bind(styles);

const Header = ({ setIsMenuOpen, isMenuOpen }) => {
   const [isShrank] = useNavbar();

   const isWide = useMedia({ minWidth: '769px' });

   const containerClass = cls('container', {
      shrank: isShrank,
      // hidden: !isVisible,
   });

   return (
      <header className={containerClass}>
         <div className={styles.headerContainer}>
            <Logo isShrank={isShrank} withTransition />
            {!isWide && (
               <BurgerIcon
                  className={styles.burgerIcon}
                  burger="Collapse"
                  active={isMenuOpen}
                  onClick={() => setIsMenuOpen(cur => !cur)}
               />
            )}
            {isWide && (
               <Nav>
                  <Nav.MenuItem label="Kontakt" href="/#kontakt" />
               </Nav>
            )}
         </div>
      </header>
   );
};

Header.propTypes = {
   setIsMenuOpen: PropTypes.func.isRequired,
   isMenuOpen: PropTypes.bool.isRequired,
};

export default Header;
