import React from 'react';
import PropTypes from 'prop-types';
import { TiSocialFacebook as FbIcon } from 'react-icons/ti';
import { navigate } from '@reach/router'; // eslint-disable-line
import classnames from 'classnames/bind';
import scroll from '../helpers/scroll';
import menu from '../data/menu';
import * as ga from '../utils/ga';
import css from './Nav.module.css';
import AppContext from './AppContext';

const cln = classnames.bind(css);

const Nav = ({ className, children, noStyles }) => {
   return (
      <nav className={cln('menu', className)}>
         {menu.map(({ label, href, blank }) => (
            <MenuItem noStyles={noStyles} key={href} label={label} href={href} blank={blank} />
         ))}
         {children}
         <a
            href="https://www.facebook.com/moskitieryroletyoknakrakow"
            target="_blank"
            rel="noopener noreferrer"
            className={css.fbIcon}
            onClick={() => {
               ga.outboundClick('https://www.facebook.com/moskitieryroletyoknakrakow');
            }}
         >
            <FbIcon size="25" />
         </a>
      </nav>
   );
};

const MenuItem = (
   { label, blank, href, className, noStyles, onClick = () => {}, ...props } // eslint-disable-line
) => {
   const { activeMenuItem, setActiveMenuItem } = React.useContext(AppContext);

   const isPathTheSame =
      typeof window !== 'undefined' && window.location.pathname === href.substr(0, href.indexOf('#')); // eslint-disable-line

   return (
      <a
         key={label}
         {...(blank && { target: '_blank', rel: 'noopener noreferrer' })}
         className={cln({ menuItem: !noStyles, active: activeMenuItem === href }, className)}
         onClick={e => {
            // eslint-disable-next-line no-negated-condition
            if (!blank) {
               e.preventDefault();
               navigate(href);
               onClick();
               setActiveMenuItem(href);
               ga.pageView();
            } else {
               ga.outboundClick(href);
            }
         }}
         {...(href[1] === '#' &&
            isPathTheSame && {
               onClick: e => {
                  navigate(href);
                  e.preventDefault();
                  scroll(href);
                  onClick();
                  setActiveMenuItem(href);
                  ga.pageView();
               },
            })}
         href={`${href}`}
         {...props}
      >
         {label}
      </a>
   );
};

Nav.MenuItem = MenuItem;

Nav.propTypes = {
   className: PropTypes.string,
   children: PropTypes.node,
   noStyles: PropTypes.bool,
};

Nav.defaultProps = {
   className: '',
   children: undefined,
   noStyles: false,
};

export default Nav;
