export default [
   {
      label: 'Start',
      href: '/#start',
   },
   {
      label: 'O nas',
      href: '/#o-nas',
   },
   {
      label: 'Produkty',
      href: '/#produkty',
   },
   {
      label: 'Wykończenia wnętrz',
      href: 'https://www.re-nowacja.pl',
      blank: true,
   },
];
