import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AOS from 'aos';
import MobileMenu from './MobileMenu';
import Header from './Header';
import Footer from './Footer';
import './Layout.scss';
import AppContext from './AppContext';

const Layout = ({ children }) => {
   const [isMenuOpen, setIsMenuOpen] = useState(false);
   const [activeMenuItem, setActiveMenuItem] = useState(
      typeof window !== 'undefined' && window.location.pathname + window.location.hash
   );

   useEffect(() => {
      AOS.init({ once: true });
   }, []);

   return (
      <AppContext.Provider value={{ activeMenuItem, setActiveMenuItem }}>
         <div id="to-start" />
         <MobileMenu isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
         <Header setIsMenuOpen={setIsMenuOpen} isMenuOpen={isMenuOpen} />
         <main>{children}</main>
         <Footer />
      </AppContext.Provider>
   );
};

Layout.propTypes = {
   children: PropTypes.node.isRequired,
};

export default Layout;
